import React, { useState, useRef, useContext } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { getJSON } from '../services/apiService.js';
import { getNetworkInfo } from '../services/helpers';

import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { useUpdateEffect, useIntersectionObserver, useSessionStorage } from 'primereact/hooks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumb from '../components/Breadcrumb';
import GetHelpButton from '../components/GetHelpButton';
import Loading from '../components/Loading';
import ProviderCard from '../components/ProviderCard';
import selectsearch from '../assets/selectsearch.png';

import AssistedSearch from './AssistedSearch';
import { PaginationContext, LoadingContext, ResultsContext, SortOrderContext } from '../pages/Layout';

function SearchResults() {
  const location = useLocation();
  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);
  const breadcrumbLinks = [
    {
      to: '.',
      label: 'Search Results',
    },
  ];

  const lazyLoader = useRef(null);
  const lazyLoaderVisible = useIntersectionObserver(lazyLoader);

  const sortMenu = useRef(null);
  const sortMenuItems = [
    {
      label: 'Closest distance',
      command: () => {
        setSelectedSortOrder('distance');
      }
    },
    {
      label: 'Rating',
      command: () => {
        setSelectedSortOrder('rating');
      }
    },
    {
      label: 'Alphabetical A-Z',
      command: () => {
        setSelectedSortOrder('alphaAsc');
      }
    },
    {
      label: 'Alphabetical Z-A',
      command: () => {
        setSelectedSortOrder('alphaDesc');
      }
    },
  ];

  const [showFilters, setShowFilters] = useState(false);
  const { searchResults, setSearchResults } = useContext(ResultsContext);
  const { loadingResults, setLoadingResults } = useContext(LoadingContext);
  const { paginationStart, setPaginationStart } = useContext(PaginationContext);
  const { selectedSortOrder, setSelectedSortOrder } = useContext(SortOrderContext);

  useUpdateEffect(() => {
    if (!loadingResults) {
      setPaginationStart(paginationStart + 10);
    }
  }, [lazyLoaderVisible]);

  return (
    <>
      <Breadcrumb links={breadcrumbLinks} copy={location.state && location.state.filterCounts ? location.state.filterCounts.totalResults + ' providers found' : ''} />
      <div className="comp-search-header">
        <div className="wrapper">
          <div className="filter-button">
            <Button className={showFilters && 'active'} onClick={() => setShowFilters(!showFilters)}>
              <FontAwesomeIcon icon={showFilters ? 'fas fa-xmark' : 'fas fa-sliders'} />
              {showFilters ? 'Close filters' : 'Adjust filters'}
            </Button>
          </div>
          <div className="sort-button">
            <Button onClick={(e) => sortMenu.current.toggle(e)}>
              <FontAwesomeIcon icon="fas fa-sort" />
              Sort Results
            </Button>
            <TieredMenu className="comp-sort-dropdown" model={sortMenuItems} popup ref={sortMenu} breakpoint="767px" />
          </div>
          <div className="compare-button">
            <Link to={'../compare'}>
              Compare saved providers
            </Link>
            <GetHelpButton />
          </div>
        </div>
      </div>
      <div className={showFilters ? 'comp-search-results showfilters' : 'comp-search-results'}>
        <div className="left">
          <AssistedSearch sideRail={true} />
        </div>
        <div className="middle">
          {searchResults && searchResults.length > 0 &&
            <div className="comp-results-grid">
              {searchResults.map((data, key) =>
                <ProviderCard key={key} data={data} />
              )}
            </div>
          }
          {loadingResults ?
            <Loading />
            :
            <div ref={lazyLoader} className="comp-lazy-loading"></div>
          }
        </div>
        {networkInfo.networkShowCards &&
          <div className="right">
            <InformationCard
              image="https://osuhealthplan.com/sites/default/files/2022-12/dedicated_support_0.jpg"
              header="DEDICATED SUPPORT"
              description="TO HELP YOU NAVIGATE YOUR HEALTH BENEFITS."
              url="https://osuhealthplan.com/programs-and-services/dedicated-support"
            />
          </div>
        }
      </div>
    </>
  )
}

function InformationCard(props) {
  return (
    <div className="comp-information-card">
      <div className="photo" style={{ backgroundImage: `url(${props.image})` }}></div>
      <div className="title">
        <strong>{props.header}</strong> {props.description}
      </div>
      <div className="link">
        <a href={props.url} target="_blank" rel="noreferrer">
          Learn More
          <FontAwesomeIcon icon="fas fa-long-arrow-right" />
        </a>
      </div>
    </div>
  )
}

export default SearchResults;
