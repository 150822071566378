import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function GetHelpButton(props) {
  const [modal, setModal] = useState(false);

  return (
    <>
      <Button className={modal ? "comp-get-help fixed" : "comp-get-help"} onClick={() => setModal(!modal)}>
        {modal &&
          <div className="info">
            614-292-4700 or 800-678-6269<br />
            Contact email: OSUHealthPlanCS@osumc.edu<br />
            Contact hours: 7:30 AM - 5:00 PM
          </div>
        }
        <div className="button">
          <FontAwesomeIcon icon="fa-regular fa-circle-question" />
          <div aria-label="GET HELP" className="copy">GET HELP</div>
        </div>
      </Button>
    </>
  );
}

export default GetHelpButton;
