import React, { useRef } from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Layout from './pages/Layout';
import ChooseNetwork from './pages/ChooseNetwork';
import AssistedSearch from './pages/AssistedSearch';
import QuickSearch from './pages/QuickSearch';
import SelectSearch from './pages/SelectSearch';
import SearchResults from './pages/SearchResults';
import Compare from './pages/Compare';

import PrimeReact from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './App.scss';

PrimeReact.ripple = true;

function App() {
  return (
    <Routes>
      <Route path={process.env.PUBLIC_URL} element={<Layout />}>
        <Route index element={<ChooseNetwork />} />
        <Route path=":networkName">
          <Route index element={<SelectSearch />} />
          <Route path="assisted-search" element={<AssistedSearch />} />
          <Route path="quick-search" element={<QuickSearch />} />
          <Route path="results" element={<SearchResults />} />
          <Route path="compare" element={<Compare />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
