import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import reportWebVitals from './reportWebVitals';

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faList, faChevronRight, faChevronUp, faSquareCheck, faSquare, faLocationDot, faCircleXmark, faXmark, faRedo, faSort, faArrowLeftLong, faArrowRightLong, faCircleMinus, faCirclePlus, faMagnifyingGlass, faSliders } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';

library.add(faList, faSquareCheck, faSquare, faLocationDot, faCircleXmark, faXmark, faRedo, faSort, faArrowLeftLong, faArrowRightLong, faCircleMinus, faCirclePlus, faMagnifyingGlass, faSliders);
library.add(faCircleQuestion, faChevronRight, faChevronUp);

// mock service worker
if (process.env.REACT_APP_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
