import React, { createContext, useState, useMemo, useRef } from 'react';
import { Link, Outlet } from "react-router-dom";

import logo from '../assets/logo.png';
import logoWhite from '../assets/logo-white.png';

import { Button } from 'primereact/button';
import { useSessionStorage, useLocalStorage } from 'primereact/hooks';

import { Toast } from "primereact/toast";

export const ToastContext = createContext({ current: null });
export const CompareContext = createContext({
  savedCompare: [],
  setSavedCompare: () => {},
});
export const PaginationContext = createContext({
  paginationStart: 0,
  setPaginationStart: () => {},
});
export const SortOrderContext = createContext({
  selectedSortOrder: 'distance',
  setSelectedSortOrder: () => {},
});
export const LoadingContext = createContext({
  loadingResults: false,
  setLoadingResults: () => {},
});
export const ResultsContext = createContext({
  searchResults: [],
  setSearchResults: () => [],
});

function Layout() {
  const [showAlert, setShowAlert] = useSessionStorage(true, 'showAlert');
  const [savedCompare, setSavedCompare] = useLocalStorage([], 'savedCompare');
  const [paginationStart, setPaginationStart] = useSessionStorage(0, 'paginationStart');
  const [selectedSortOrder, setSelectedSortOrder] = useSessionStorage('distance', 'selectedSortOrder');
  const [loadingResults, setLoadingResults] = useSessionStorage(false, 'loadingResults');
  const [searchResults, setSearchResults] = useSessionStorage([], 'searchResults');

  const toast = useRef(null);
  const savedCompareValue = useMemo(
    () => ({ savedCompare, setSavedCompare }),
    [savedCompare]
  );
  const loadingResultsValue = useMemo(
    () => ({ loadingResults, setLoadingResults }),
    [loadingResults]
  );
  const paginationStartValue = useMemo(
    () => ({ paginationStart, setPaginationStart }),
    [paginationStart]
  );
  const selectedSortOrderValue = useMemo(
    () => ({ selectedSortOrder, setSelectedSortOrder }),
    [selectedSortOrder]
  );
  const searchResultsValue = useMemo(
    () => ({ searchResults, setSearchResults }),
    [searchResults]
  );

  return (
    <CompareContext.Provider value={savedCompareValue}>
      <ToastContext.Provider value={toast}>
      <PaginationContext.Provider value={paginationStartValue}>
      <SortOrderContext.Provider value={selectedSortOrderValue}>
      <LoadingContext.Provider value={loadingResultsValue}>
      <ResultsContext.Provider value={searchResultsValue}>
        <div className="App">
          <header>
            <div className="wrapper">
              <img src={logo} alt="The Ohio State University Health Plan" />
            </div>
          </header>
          {showAlert &&
            <div className="comp-message">
              <div className="wrapper">
                <div className="text">
                  Be aware that provider charges are paid according to the network the provider participates in (Premier or Standard). Facility charges are paid according to the network the facility participates in (Premier or Standard).
                </div>
                <Button icon="pi pi-times" rounded text severity="danger" aria-label="Cancel" onClick={() => setShowAlert(false)} />
              </div>
            </div>
          }
          {useMemo(() => (
            <Outlet />
          ), [])}
          <footer>
            <div className="wrapper">
              <img src={logoWhite} alt="The Ohio State University Health Plan" />
              <div className="links">
                <ul className="prim-nav">
                  <li><a href="#">Programs & Services</a></li>
                  <li><a href="#">Find a Provider</a></li>
                  <li><a href="#">Health Plan Benefits</a></li>
                  <li><a href="#">Health Plan Tools</a></li>
                  <li><a href="#">About Us</a></li>
                </ul>
                <ul className="sec-nav">
                  <li><a href="#">For Providers</a></li>
                  <li><a href="#">Contact</a></li>
                  <li><a href="#">Classes and Events</a></li>
                  <li><a href="#">News</a></li>
                  <li><a href="#">Search</a></li>
                  <li><a href="#">Translations</a></li>
                  <li><a href="#">Required Notices</a></li>
                </ul>
              </div>
              <div className="copy">
                © Copyright 2023 OSU Health Plan. All Rights Reserved.<br/>
                OSU Health Plan operates in affiliation with The Ohio State University
              </div>
            </div>
          </footer>
          <div className="comp-ada">
            <div className="wrapper">
              If you have a disability and experience difficulty accessing this content, contact the Health Plan at <a href="mailto:OSUHPDigitalAccessibility@osumc.edu">OSUHPDigitalAccessibility@osumc.edu</a>.
            </div>
          </div>
          <Toast ref={toast} position="bottom-left" />
        </div>
      </ResultsContext.Provider>
      </LoadingContext.Provider>
      </SortOrderContext.Provider>
      </PaginationContext.Provider>
      </ToastContext.Provider>
    </CompareContext.Provider>
  )
}

export default Layout;
