import React from 'react';

import person from '../assets/profile-person.png';
import location from '../assets/profile-location.png';

function ProfileImage(props) {

  // profile photo
  let src = person;
  if (props.imageUrl) {
    src = props.imageUrl;
  } else if (props.individualFacility == 'F') {
    src = location;
  } else if (props.individualFacility == 'I') {
    src = person;
  }

  return (
    <img src={src} className="profile-image" alt="" />
  );
}

export default ProfileImage;
