const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export async function postJSON(url, data) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  const response = await fetch(`${process.env.REACT_APP_SERVER_PREFIX}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers
  });

  return await response.json()
}

export async function getJSON(url, parameters) {
  const params = new URLSearchParams(parameters);
  const response = await fetch(`${process.env.REACT_APP_SERVER_PREFIX}${url}?` + params, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  // add slight delay for development environment
  if (process.env.REACT_APP_ENV === 'development') {
    await delay(1000);
  }

  return await response.json();
}
