import React from 'react';
import { Link, useParams } from "react-router-dom";
import { sessionClear } from '../services/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import providers from '../assets/providers.png';
import Breadcrumb from '../components/Breadcrumb';
import GetHelpButton from '../components/GetHelpButton';

function ChooseNetwork() {
  sessionClear();

  const params = useParams();
  const breadcrumbLinks = [
    {
      to: '/',
      label: 'Choose network',
    }
  ];

  return (
    <>
      <Breadcrumb links={breadcrumbLinks} />
      <div className="comp-choosenetwork">
        <div className="wrapper">
          <div className="top">
            <h1>
              Find a Provider.
            </h1>
            <div className="copy">
              Get started by selecting your network.
            </div>
            <img src={providers} alt="" />
          </div>
          <div className="bottom">
            <div className="networks">
              <div className="section">
                {window.networks.map((network, index) =>
                  <>
                    {network.networkChoice &&
                      <Link key={network.networkId} to={network.networkName}>
                        <FontAwesomeIcon className="active" icon="fa-solid fa-square-check" />
                        <FontAwesomeIcon className="inactive" icon="fa-solid fa-square" />
                        <span>
                          {network.networkDisplayName}
                        </span>
                        <img src={network.networkLogo} alt="" />
                      </Link>
                    }
                  </>
                )}
              </div>
            </div>
            <div className="notice">
              This tool does not provide medical advice. It is intended for informational purposes only. It is not a substitute for professional medical advice, diagnosis or treatment.<br /><br />
              All data is reported to us by each provider group. We make every effort to share up-to-date information, but this information may change without our being notified. Providers should be contacted directly to confirm status.
            </div>
          </div>
        </div>
      </div>
      <GetHelpButton />
    </>
  );
}

export default ChooseNetwork;
