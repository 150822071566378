import React, { useState } from 'react';
import { uniq } from 'lodash';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AutoComplete } from "primereact/autocomplete";
import { useSessionStorage } from 'primereact/hooks';
import { getJSON } from '../services/apiService';
import { getNetworkInfo } from '../services/helpers';
import Breadcrumb from '../components/Breadcrumb';
import GetHelpButton from '../components/GetHelpButton';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function QuickSearch() {
  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);
  const navigate = useNavigate();
  const breadcrumbLinks = [
    {
      to: '/',
      label: 'Choose network',
    },
    {
      to: '/' + networkName,
      label: 'Start your search',
    },
    {
      to: '',
      label: 'Quick Search',
    },
  ];
  const [searchText, setSearchText] = useSessionStorage('', 'searchText');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getResults = () => {
    setLoading(true);
    getJSON('/predictive-text', {
      network: networkInfo.networkId,
      text: searchText,
    }).then((response) => {
      setResults(uniq(response));
      setLoading(false);
    });
  };

  const onGoToResults = (e) => {
    navigate('../results');
  }

  return (
    <>
      <Breadcrumb links={breadcrumbLinks} />
      <div className="comp-search-input">
        <div className="header">
          <FontAwesomeIcon icon="fa-solid fa-search" />
          QUICK SEARCH
        </div>
        <div className="copy">
          What do you need help with?
        </div>
        <div className="form">
          <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
          <AutoComplete value={searchText} suggestions={results} completeMethod={getResults} onChange={(e) => setSearchText(e.value)} scrollHeight="25vh" appendTo="self" />
          <Button label="Search" onClick={onGoToResults} />
          {loading && <Loading />}
        </div>
      </div>
      {results && results.length > 0 &&
        <div className="comp-quick-search-results"></div>
      }
      <GetHelpButton />
    </>
  );
}

export default QuickSearch;
