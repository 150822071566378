import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { useSessionStorage } from 'primereact/hooks';
import { Button } from 'primereact/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getJSON } from '../services/apiService.js';
import { getNetworkInfo } from '../services/helpers';
import ProviderCard from '../components/ProviderCard';
import Breadcrumb from '../components/Breadcrumb';
import GetHelpButton from '../components/GetHelpButton';
import Loading from '../components/Loading';

import { CompareContext } from './Layout';

function Compare() {
  const { savedCompare, setSavedCompare } = useContext(CompareContext);
  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);
  const [compare, setCompare] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCardData = () => {
    setLoading(true);
    getJSON('/compare', {
      network: networkInfo.networkId,
      locationIds: savedCompare,
    }).then((response) => {
      setCompare(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    getCardData();
  }, [savedCompare]);

  return (
    <>
      <Breadcrumb />
      <div className="comp-search-header">
        <div className="wrapper">
          <div className="filter-button">
            <Button onClick={() => setSavedCompare([])}>
              <FontAwesomeIcon icon="fas fa-xmark" />
              Clear saved
            </Button>
          </div>
        </div>
      </div>
      {loading ?
        <div className="comp-compare-loading">
          <Loading />
        </div>
        :
        <>
          <div className="comp-compare-grid">
            {compare.map((data, key) =>
              <ProviderCard key={key} data={data} comparePage={true} />
            )}
          </div>
        </>
      }
      <GetHelpButton />
    </>
  );
}

export default Compare;
