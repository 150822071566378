import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { sessionClear, getNetworkInfo } from '../services/helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, AccordionTab } from 'primereact/accordion';
import Breadcrumb from '../components/Breadcrumb';
import GetHelpButton from '../components/GetHelpButton';
import selectsearch from '../assets/selectsearch.png';

function SelectSearch() {
  sessionClear();

  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);
  const breadcrumbLinks = [
    {
      to: '/',
      label: 'Choose network',
    },
    {
      to: '/' + networkName,
      label: 'Start your search',
    },
  ];

  return (
    <>
      <Breadcrumb links={breadcrumbLinks} />
      <div className="comp-selectsearch">
        <div className="top">
          <div className="image">
            <img src={selectsearch} />
          </div>
          <div className="cards l">
            <Link to={'quick-search'} className="card">
              <div className="header">
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                QUICK SEARCH
              </div>
              <div className="copy">
                Explainer copy on who/when quick search should be used. Cras varius erat orci, non vulputate nunc consequat blandit. Praesent cursus dolor in lectus lacinia, ut gravida orci gravida.
              </div>
              <div className="link">
                Start quick search
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
              </div>
            </Link>
          </div>
          <div className="cards r">
            <Link to={'assisted-search'} className="card">
              <div className="header">
                <FontAwesomeIcon icon="fa-solid fa-list" />
                ASSISTED SEARCH
              </div>
              <div className="copy">
                Explainer copy on who/when assisted search should be used. Cras varius erat orci, non vulputate nunc consequat blandit. Praesent cursus dolor in lectus lacinia, ut orci gravida.
              </div>
              <div className="link">
                Start assisted search
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-long" />
              </div>
            </Link>
          </div>
        </div>
        <div className="bottom">
          <div className="header">
            FAQs
          </div>
          <Accordion multiple={true}>
          {networkInfo.networkFaqs && networkInfo.networkFaqs.map((faq, index) => {
            return (
              <AccordionTab key={index} header={
                <div>
                  <FontAwesomeIcon className="plus" icon="fa-solid fa-circle-plus" />
                  <FontAwesomeIcon className="minus" icon="fa-solid fa-circle-minus" />
                  {faq.question}
                </div>
              }>
                {faq.answer}
              </AccordionTab>
            )
          })}
          </Accordion>
        </div>
      </div>
      <GetHelpButton />
    </>
  );
}

export default SelectSearch;
