import React, { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displayGender } from '../services/helpers';

function FilterGroup(props) {
  const [open, setOpen] = useState(false);
  const getOptions = () => {
    return props.data.map((item) => {
      if (item.count) {
        if (item.name) {
          return { ...item, displayName: (props.formatGender ? displayGender(item.name) : item.name) + ' (' + item.count + ')' };
        } else {
          return { ...item, displayName: 'None' + ' (' + item.count + ')' };
        }
      } else {
        return { ...item, displayName: (props.formatGender ? displayGender(item.name) : item.name) };
      }
    })
  };
  const maxCheckboxes = 5;

  return (
    <div className={open ? "group" : "group collapsed"}>
      <div className="heading">
        <span>{props.label}</span>
        <Button className="comp-filter-chevron" aria-label="Toggle location" onClick={() => setOpen(!open)}>
          {open ?
            <FontAwesomeIcon icon="fa-solid fa-chevron-up" />
            :
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
          }
        </Button>
      </div>
      {props.type === 'text' &&
        <div className="list">
          <InputText value={props.value} onChange={props.onChange} />
        </div>
      }
      {props.type === 'checkbox' && props.data && props.data.length > maxCheckboxes &&
        <div className="list">
          <MultiSelect
            value={props.checked}
            onChange={props.onChange}
            options={getOptions()}
            optionLabel="displayName"
            optionValue="id"
            display="chip"
            placeholder={"Select " + props.label}
            maxSelectedLabels={5}
            className=""
          />
        </div>
      }
      {props.type === 'checkbox' && props.data && props.data.length <= maxCheckboxes &&
        <div className="list">
          {getOptions().map((item) =>
            <div key={item.id} className="result">
              <Checkbox
                inputId={props.name + item.id}
                name={props.name}
                value={item.id}
                checked={Array.isArray(props.checked) ? props.checked.includes(item.id) : props.checked}
                onChange={props.onChange}
              />
              <label htmlFor={props.name + item.id}>
                {item.displayName}
              </label>
            </div>
          )}
        </div>
      }
      {props.note &&
        <div className="note">
          {props.note}
        </div>
      }
    </div>
  )
}

export default FilterGroup;
