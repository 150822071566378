import { useState, useEffect } from 'react';
import { find } from 'lodash';

// clear all but alert data
export function sessionClear() {
  const showAlert = sessionStorage.getItem('showAlert');
  sessionStorage.clear();
  if (showAlert) sessionStorage.setItem('showAlert', showAlert);
}

// display formatted genders
export function displayGender(gender) {
  if (gender === 'M') {
    return ('Male Provider');
  } else if (gender === 'F') {
    return ('Female Provider');
  } else if (gender === 'N') {
    return ('Non-binary Provider');
  } else {
    return ('Provider');
  }
}

// get network info
export function getNetworkInfo(networkName) {
  return find(window.networks, (networkInfo) => {
    return networkInfo.networkName == networkName
  })
}

// detect truncated (clamped) element
export const useIsTruncated = (element) => {

  const determineIsTruncated = () => {
    if (!element.current) return false;
    return element.current.scrollHeight > element.current.clientHeight;
  };

  const [isTruncated, setIsTruncated] = useState(determineIsTruncated());

  useEffect(() => {
    const resizeListener = () => setIsTruncated(determineIsTruncated());
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return isTruncated;
};
