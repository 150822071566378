import React from 'react';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { getNetworkInfo } from '../services/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Breadcrumb(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);
  const path = location.pathname.toString();

  const showBack = () => {
    return networkName;
  }
  const showRestart = () => {
    return path.includes('assisted-search') || path.includes('compare') || path.includes('quick-search') || path.includes('results');
  }

  return (
    <nav className="comp-breadcrumb">
      <div className="wrapper">
        {props.links &&
          <ul className="links">
            {props.links && props.links.map((link, index) =>
              <li key={index}>
                {link.to ?
                  <Link to={process.env.PUBLIC_URL + link.to}>
                    {link.label}
                  </Link>
                  :
                  <span>
                    {link.label}
                  </span>
                }
              </li>
            )}
            {props.copy &&
              <li>
                {props.copy}
              </li>
            }
          </ul>
        }
        <ul className="actions">
          {showBack() &&
            <li>
              <Link to={-1}>
                <FontAwesomeIcon icon="fas fa-long-arrow-left" />
                Back
              </Link>
            </li>
          }
          {showRestart() &&
            <li>
              <Link to={process.env.PUBLIC_URL} reloadDocument={true}>
                <FontAwesomeIcon icon="fas fa-redo" />
                Restart your search
              </Link>
            </li>
          }
        </ul>
      </div>
    </nav>
  );
}

export default Breadcrumb;
