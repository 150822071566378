import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useParams, useNavigate } from "react-router-dom";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Slider } from "primereact/slider";
import { Checkbox } from "primereact/checkbox";
import { useSessionStorage, useUpdateEffect } from 'primereact/hooks';
import { getJSON } from '../services/apiService';
import { getNetworkInfo } from '../services/helpers';
import Breadcrumb from '../components/Breadcrumb';
import GetHelpButton from '../components/GetHelpButton';
import Loading from '../components/Loading';
import FilterGroup from '../components/FilterGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContext, PaginationContext, LoadingContext, ResultsContext, SortOrderContext } from './Layout';

// TODO: load map markers into google map embed with available data

function AssistedSearch(props) {
  const toast = useContext(ToastContext);
  const navigate = useNavigate();
  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);
  const breadcrumbLinks = [
    {
      to: '/',
      label: 'Choose network',
    },
    {
      to: '/' + networkName,
      label: 'Start your search',
    },
    {
      to: '',
      label: 'Assisted Search',
    },
  ];
  const defaultMapProps = {
    center: {
      lat: 39.9953,
      lng: -83.0179
    },
    zoom: 7
  };
  /*const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCna7nFsfb9jLlR4uTgfm9aP74phIpI4T0"
  })
  const [map, setMap] = React.useState(null)
  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(defaultMapProps.center);
    //map.fitBounds(bounds);
    setMap(map)
  }, [])
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])*/

  const [ailmentInputValue, setAilmentInputValue] = useSessionStorage('', 'ailmentInputValue');
  const [ailmentExpanded, setAilmentExpanded] = useState(false);
  const [ailmentLoading, setAilmentLoading] = useState(false);
  const [specialties, setSpecialties] = useSessionStorage([], 'specialties');

  const [filtersLoading, setFiltersLoading] = useState(false);
  const { paginationStart, setPaginationStart } = useContext(PaginationContext);
  const { selectedSortOrder, setSelectedSortOrder } = useContext(SortOrderContext);
  const [searchText, setSearchText] = useSessionStorage('', 'searchText');
  const [lat, setLat] = useSessionStorage(null);
  const [lon, setLon] = useSessionStorage(null);
  const [radius, setRadius] = useSessionStorage(20, 'radius');
  const [radiusStep, setRadiusStep] = useSessionStorage(20, 'radiusStep');
  const [dynamicFilters, setDynamicFilters] = useSessionStorage(null, 'dynamicFilters');
  const [selectedSpecialties, setSelectedSpecialties] = useSessionStorage([], 'selectedSpecialties');
  const [selectedLanguages, setSelectedLanguages] = useSessionStorage([], 'selectedLanguages');
  const [selectedProviderTypes, setSelectedProviderTypes] = useSessionStorage([], 'selectedProviderTypes');
  const [selectedDemographicSpecialties, setSelectedDemographicSpecialties] = useSessionStorage([], 'selectedDemographicSpecialties');
  const [selectedTreatmentOptions, setSelectedTreatmentOptions] = useSessionStorage([], 'selectedTreatmentOptions');
  const [selectedGenders, setSelectedGenders] = useSessionStorage([], 'selectedGenders');
  const [selectedTiers, setSelectedTiers] = useSessionStorage([], 'selectedTiers');
  const [acceptingNewPatients, setAcceptingNewPatients] = useSessionStorage(false, 'acceptingNewPatients');

  const ailmentSubmit = () => {
    setSpecialties([]);
    setSelectedSpecialties([]);
    setAilmentLoading(true);
    getJSON('/specialties', {
      network: networkInfo.networkId,
      text: ailmentInputValue,
    }).then((response) => {
      setSpecialties(response);
      setAilmentLoading(false);
    });
  };

  const getFilters = () => {
    setFiltersLoading(true);
    getJSON('/filter-count', getSearchFields()).then((response) => {
      setDynamicFilters(response);
      setFiltersLoading(false);
    });
  };

  const getSearchFields = (pagination) => {
    return {
      searchText: searchText,
      latitude: lat ? lat : defaultMapProps.center.lat,
      longitude: lon ? lat : defaultMapProps.center.lng,
      radius: radius,
      networkId: networkInfo.networkId,
      specialtyIds: selectedSpecialties,
      languageIds: selectedLanguages,
      providerTypeIds: selectedProviderTypes,
      genders: selectedGenders,
      tierIds: selectedTiers,
      demographicSpecialtyIds: selectedDemographicSpecialties,
      treatmentOptionIds: selectedTreatmentOptions,
      acceptingNewPatients: acceptingNewPatients,
      sortOption: selectedSortOrder,
      start: pagination,
      end: pagination + 10,
    }
  };

  const { searchResults, setSearchResults } = useContext(ResultsContext);
  const { loadingResults, setLoadingResults } = useContext(LoadingContext);
  const getSearchResults = (append) => {
    setLoadingResults(true);
    if (!append) {
      setPaginationStart(0);
      setSearchResults([]);
    }
    getJSON('/search', getSearchFields(!append ? 0 : paginationStart)).then((response) => {
      if (append) {
        setSearchResults([...searchResults, ...response]);
      } else {
        setSearchResults(response);
      }
      setLoadingResults(false);
    });
  };

  const onGoToResults = (e) => {
    navigate('../results');
  }

  const onFiltersChange = (stateName, setStateName, e) => {
    if (Array.isArray(e.value)) {
      setStateName(e.value);
    } else {
      let _array = [...stateName];

      if (e.checked)
        _array.push(e.value);
      else
        _array.splice(_array.indexOf(e.value), 1);

      setStateName(_array);
    }
  }

  const getGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setGeolocation, showGeolocationError);
    } else {
      toast.current.show({ severity:'error', summary: 'Error', detail: 'Geolocation is not supported by this browser.', life: 3000 });
    }
  }
  const setGeolocation = (position) => {
    setLat(position.coords.latitude);
    setLon(position.coords.longitude);
    toast.current.show({ severity:'success', summary: 'Success', detail: 'Current location has been set.', life: 3000 });
  }
  const showGeolocationError = (error) => {
    let message = 'An unknown error occurred.';
    switch(error.code) {
      case error.PERMISSION_DENIED:
        message = "The browser denied the request for the current location."
        break;
      case error.POSITION_UNAVAILABLE:
        message = "Location information is unavailable."
        break;
      case error.TIMEOUT:
        message = "The request to get current location timed out."
        break;
      case error.UNKNOWN_ERROR:
        message = "An unknown error occurred."
        break;
    }
    toast.current.show({ severity:'error', summary: 'Error', detail: message, life: 3000 });
  }

  const updateRadius = (e) => {
    setRadius(e.value < 30 ? e.value : Math.ceil(e.value / 5) * 5);
    setRadiusStep(e.value >= 30 ? 5 : 1);
  }

  useUpdateEffect(() => {
    getSearchResults(false);
    getFilters();
  }, [selectedSpecialties, selectedTiers, selectedProviderTypes, selectedTreatmentOptions, selectedGenders, selectedLanguages, selectedDemographicSpecialties, acceptingNewPatients, searchText, selectedSortOrder]);

  useUpdateEffect(() => {
    if (!loadingResults) {
      getSearchResults(true);
    }
  }, [paginationStart]);

  return (
    <>
      {!props.sideRail &&
        <>
          <Breadcrumb links={breadcrumbLinks} />
          <div className="comp-search-input">
            <div className="header">
              <FontAwesomeIcon icon="fa-solid fa-list" />
              ASSISTED SEARCH
            </div>
            <div className="copy">
              Prompt about ailment. What do you need help with?
            </div>
            <div className="form">
              <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
              <InputText value={ailmentInputValue} onChange={(e) => setAilmentInputValue(e.target.value)} />
              <Button label="Search" onClick={ailmentSubmit} />
              {ailmentLoading && <Loading />}
            </div>
          </div>
          {specialties && specialties.length > 0 &&
            <div className="comp-specialties-results">
              <div className="header">
                Which of these best describe your needs?
              </div>
              <div className="results">
                {specialties.map((specialty, index) => {
                  return (
                    <div key={specialty.specialtyId} className={!ailmentExpanded && index >= 5 ? 'result hidden' : 'result'}>
                      <Checkbox
                        inputId={specialty.specialtyId}
                        name="specialty"
                        value={specialty.specialtyId}
                        onChange={onFiltersChange.bind(null, selectedSpecialties, setSelectedSpecialties)}
                        checked={selectedSpecialties.some((id) => id === specialty.specialtyId)} />
                      <label htmlFor={specialty.specialtyId}>
                        {specialty.specialtyName}{specialty.specialtyDescription && specialty.specialtyDescription !== specialty.specialtyName && ' - ' + specialty.specialtyDescription }
                      </label>
                    </div>
                  )
                })}
              </div>
              <div className="expand-toggle">
                {!ailmentExpanded ?
                  <button className="plus" onClick={() => setAilmentExpanded(true)}>Expand for more results</button>
                  :
                  <button className="minus" onClick={() => setAilmentExpanded(false)}>Collapse for less results</button>
                }
              </div>
            </div>
          }
        </>
      }
      {props.sideRail || (dynamicFilters && selectedSpecialties && selectedSpecialties.length > 0) ?
        <div className={props.sideRail ? "comp-filter-container siderail" : "comp-filter-container"}>
          <div className={props.sideRail ? "comp-filter-results siderail" : "comp-filter-results"}>
            <div className="wrapper">
              {!props.sideRail &&
                <Button className="see-results" label={'See ' + dynamicFilters.count + ' Results'} onClick={onGoToResults} />
              }
              <div className="header">
                FILTER RESULTS
              </div>
              <div className="card">
                <div className="locationcap">
                  <span>
                    Location
                  </span>
                  <Button className="comp-filter-chevron" aria-label="Toggle location">
                    <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                  </Button>
                </div>
                <div className="location">
                  <div className="inputs">
                    <div className="title">
                      Select location:
                    </div>
                    <div className="buttons">
                      <Button onClick={getGeolocation}>
                        <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                        Use current location
                      </Button>
                      <div className="lookup">
                        <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />
                        <InputText placeholder="Search by address or ZIP code" />
                      </div>
                    </div>
                    <div className="title">
                      Refine distance:
                    </div>
                    <div className="radius">
                      <div className="current">
                        <span style={{ left: (radius) + '%' }}>{radius} mi.</span>
                      </div>
                      <div className="input">
                        <Slider value={radius} onChange={updateRadius} step={radiusStep} min={1} max={100} />
                      </div>
                      <div className="min">1 mi.</div>
                      <div className="max">100 mi.</div>
                    </div>
                  </div>
                  <div className="map">
                    <div className="embed">
                      {/*}<GoogleMap
                        center={defaultMapProps.center}
                        zoom={defaultMapProps.zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        {searchResults.map((marker, index) => {
                          <MapMarker
                            key={index}
                            position={{
                              lat: marker.location.lat,
                              lng: marker.location.lon,
                            }}
                            text="Marker"
                          />
                        })}
                        <></>
                      </GoogleMap>
                      */}
                    </div>
                    <div className="link">
                      <a href="#">Show public transportation routes</a>
                    </div>
                  </div>
                </div>
                <div className="filtergroups">
                  <div className="title">
                    Choose additional filters:
                  </div>
                  <div className="groups">
                    {props.sideRail &&
                      <FilterGroup
                        type="text"
                        label="Keyword"
                        value={searchText}
                        onChange={(e) => setSearchText(e.value)}
                        name="searchText" />
                    }
                    {dynamicFilters && dynamicFilters.tiers &&
                      <FilterGroup
                        type="checkbox"
                        label="Tiers"
                        data={dynamicFilters.tiers}
                        checked={selectedTiers}
                        onChange={onFiltersChange.bind(null, selectedTiers, setSelectedTiers)}
                        name="selectedTiers" />
                    }
                    {dynamicFilters && dynamicFilters.providerTypes &&
                      <FilterGroup
                        type="checkbox"
                        label="Provider Types"
                        data={dynamicFilters.providerTypes}
                        checked={selectedProviderTypes}
                        onChange={onFiltersChange.bind(null, selectedProviderTypes, setSelectedProviderTypes)}
                        name="selectedProviderTypes" />
                    }
                    {dynamicFilters && dynamicFilters.treatmentOptions &&
                      <FilterGroup
                        type="checkbox"
                        label="Treatment Options"
                        data={dynamicFilters.treatmentOptions}
                        checked={selectedTreatmentOptions}
                        onChange={onFiltersChange.bind(null, selectedTreatmentOptions, setSelectedTreatmentOptions)}
                        name="selectedTreatmentOptions" />
                    }
                    {dynamicFilters && dynamicFilters.genders &&
                      <FilterGroup
                        type="checkbox"
                        label="Provider Gender"
                        data={dynamicFilters.genders}
                        checked={selectedGenders}
                        onChange={onFiltersChange.bind(null, selectedGenders, setSelectedGenders)}
                        name="selectedGenders"
                        formatGender={true} />
                    }
                    {dynamicFilters && dynamicFilters.languages &&
                      <FilterGroup
                        type="checkbox"
                        label="Language"
                        data={dynamicFilters.languages}
                        checked={selectedLanguages}
                        onChange={onFiltersChange.bind(null, selectedLanguages, setSelectedLanguages)}
                        name="selectedLanguages" />
                    }
                    {dynamicFilters && dynamicFilters.demographicSpecialties &&
                      <FilterGroup
                        type="checkbox"
                        label="Demographic Specialties"
                        data={dynamicFilters.demographicSpecialties}
                        checked={selectedDemographicSpecialties}
                        onChange={onFiltersChange.bind(null, selectedDemographicSpecialties, setSelectedDemographicSpecialties)}
                        name="selectedDemographicSpecialties" />
                    }
                    <FilterGroup
                      type="checkbox"
                      label="Accepting New Patients"
                      note="This indicator is reported to us by each provider group and may change without our being notified. Providers should be contacted directly to confirm new patient status."
                      data={[{
                        id: 1,
                        name: 'Yes',
                      }]}
                      checked={acceptingNewPatients}
                      onChange={() => setAcceptingNewPatients(!acceptingNewPatients)}
                      name="acceptingNewPatients" />
                  </div>
                </div>
                {!props.sideRail &&
                  <Button className="see-results endcap" label={'See ' + dynamicFilters.count + ' Results'} onClick={onGoToResults} />
                }
              </div>
            </div>
          </div>
        </div>
        :
        <></>
      }
      {!props.sideRail &&
        <GetHelpButton />
      }
    </>
  )
}

function MapMarker(props) {
  return (
    <div className="comp-mapmarker">
      <FontAwesomeIcon icon="fa-solid fa-location-dot" />
      Marker
    </div>
  )
}

export default AssistedSearch;
