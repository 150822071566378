import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

function Loading(props) {
  return (
    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="6" fill="#fff" animationDuration=".5s" />
  );
}

export default Loading;
