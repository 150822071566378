import React, { useState, useContext, useRef, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { uniq, without } from 'lodash';
import { Button } from 'primereact/button';
import PrimeReact from 'primereact/api';
import StarRating from './StarRating';
import ProfileImage from './ProfileImage';
import Loading from './Loading';
import { getJSON } from '../services/apiService';
import { useIsTruncated, displayGender, getNetworkInfo } from '../services/helpers';

import { useSessionStorage } from 'primereact/hooks';
import { Dialog } from 'primereact/dialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CompareContext, ToastContext } from '../pages/Layout';

function ProviderCard(props) {
  const toast = useContext(ToastContext);
  const { savedCompare, setSavedCompare } = useContext(CompareContext);
  const { networkName } = useParams();
  const networkInfo = getNetworkInfo(networkName);

  const data = props.data;
  const location = data.address1 + (data.address2 ? ', ' + data.address2 : '') + ', ' + data.city + ', ' + data.state + ' ' + data.zip;

  const [modalProviders, setModalProviders] = useState(false);
  const [modalLocations, setModalLocations] = useState(false);
  const [modalMore, setModalMore] = useState(false);

  const saveProvider = (id) => {
    if (savedCompare.indexOf(id) > -1) {
      setSavedCompare(without(savedCompare, id));
      toast.current.show({ severity:'success', summary: 'Success', detail: 'Provider has been removed.', life: 3000 });
    } else {
      setSavedCompare(uniq([...savedCompare, ...[id]]));
      toast.current.show({ severity:'success', summary: 'Success', detail: 'Provider has been saved.', life: 3000 });
    }
  }

  const refDemographics = useRef(null);
  const isTruncatedDemographics = useIsTruncated(refDemographics);

  const refSpecialties = useRef(null);
  const isTruncatedSpecialties = useIsTruncated(refSpecialties);

  const refNotes = useRef(null);
  const isTruncatedNotes = useIsTruncated(refNotes);

  return (
    <>
      <div className={props.withinModal ? "comp-modal-provider-card" : "comp-provider-card"}>
        <div className="top">
          <div className="left">
            <StarRating rating={data.rating} ratingUrl={data.ratingUrl} />
            <div className="images">
              <div>
                <ProfileImage imageUrl={data.imageUrl} individualFacility={data.individualFacility} />
              </div>
              <div>
                {data.logoFlag == 'Y' ?
                  <img src={'/assets/logo-osu-affiliated.png'} className="network-logo" />
                  : null
                }
              </div>
            </div>
            <div className="subimages">
              <div>
                {data.gender &&
                  displayGender(data.gender)
                }
              </div>
              <div>
                {data.profileUrl &&
                  <a href={data.profileUrl} target="_blank" rel="noreferrer" className="profile-link">
                    Physician profile
                  </a>
                }
              </div>
            </div>
          </div>
          <div className="right">
            {data.acceptingNewPatients === 'Y' &&
              <div className="accepting-new-patients">
                Accepting new patients
              </div>
            }
            <div className="languages">
              {data.languages}
            </div>
            <div className="network">
              Premier Network
            </div>
          </div>
        </div>
        <div className="middle">
          <div className="name">
            {data.providerDisplayName}{data.degree && <>, {data.degree}</>}
          </div>
          <div className="location">
            {props.withinModal &&
              <Button className="button" onClick={props.closeModal}>
                Show less
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
              </Button>
            }
            <div className="name">
              {data.providerLocationName}
            </div>
            <div className="address">
              {location}
              &nbsp;|&nbsp;
              <a href={"https://maps.google.com/?q=" + location} target="_blank" rel="noreferrer">Directions</a>
            </div>
            <div className="phone">
              {data.phonenumber}
            </div>
          </div>
          <div className="location-links">
            <div className="modals">
              {data.individualFacility === 'I' &&
                <>
                  <Button link onClick={() => setModalLocations(!modalLocations)}>See other locations for this provider</Button>
                  <Button link onClick={() => setModalProviders(!modalProviders)}>See other providers for this location</Button>
                </>
              }
            </div>
            {data.telehealth &&
              <div className="telehealth">
                <FontAwesomeIcon icon="fa-solid fa-square-check" />
                Telehealth options
              </div>
            }
          </div>
        </div>
        <div className="bottom">
          <div className="attribute lines2 noborder">
            <div ref={refSpecialties}>
              {data.specialties && data.specialties.map((specialty, index, row) =>
                <span key={index}>
                  {index + 1 === row.length ?
                    <>
                      {specialty.name}{specialty.boardCertified === 'Y' && ' (board certified)'}
                    </>
                  :
                    <>
                      {specialty.name}{specialty.boardCertified === 'Y' && ' (board certified)'}{', '}
                    </>
                  }
                </span>
              )}
            </div>
            {isTruncatedSpecialties &&
              <Button link onClick={() => setModalMore(!modalMore)}>
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                More
              </Button>
            }
          </div>
          <div className={data.demographicSpecialties && data.demographicSpecialties.length > 0 ? "attribute lines1" : "attribute lines1 noborder"}>
            <div ref={refDemographics}>
              {data.demographicSpecialties && data.demographicSpecialties.map((specialty, index, row) =>
                <span key={index}>
                  {index + 1 === row.length ? specialty.name : specialty.name + ', '}
                </span>
              )}
            </div>
            {isTruncatedDemographics &&
              <Button link onClick={() => setModalMore(!modalMore)}>
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                More
              </Button>
            }
          </div>
          <div className="attribute lines1">
            <div ref={refNotes}>
              Notes: {data.notes}
            </div>
            {isTruncatedNotes &&
              <Button link onClick={() => setModalMore(!modalMore)}>
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                More
              </Button>
            }
          </div>
        </div>
        <div className="save-this-provider">
          <Button link onClick={() => saveProvider(data.providerLocationId)}>
            {savedCompare.indexOf(data.providerLocationId) > -1 ?
              'Remove from compare' : 'Save to compare'
            }
          </Button>
        </div>
      </div>
      <Dialog dismissableMask={true} blockScroll={true} showHeader={false} draggable={false} visible={modalMore} style={{ width: '70vw' }} onHide={() => setModalMore(false)}>
        <ProviderCard data={data} withinModal={true} closeModal={() => setModalMore(false)} />
      </Dialog>
      <Dialog dismissableMask={true} blockScroll={true} showHeader={false} draggable={false} visible={modalLocations} style={{ width: '70vw' }} onHide={() => setModalLocations(false)}>
        <div className="comp-modal-header">
          <div className="title">
            <small>Other locations for</small>
             {data.providerDisplayName}{data.degree && <>, {data.degree}</>}
          </div>
          <Button className="button" onClick={() => setModalLocations(false)}>
            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
            Close
          </Button>
        </div>
        <OtherLocations providerId={data.providerId} networkId={networkInfo.networkId} />
      </Dialog>
      <Dialog dismissableMask={true} blockScroll={true} showHeader={false} draggable={false} visible={modalProviders} style={{ width: '70vw' }} onHide={() => setModalProviders(false)}>
        <div className="comp-modal-header">
          <div className="title">
            <small>Other providers for</small>
            {data.name}
          </div>
          <Button className="button" onClick={() => setModalProviders(false)}>
            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
            Close
          </Button>
        </div>
        {false ? // TODO: load data
          <div className="comp-modal-loading">
            <Loading />
          </div>
          :
          <div className="comp-modal-grid">
            {Array.from({ length: 8 }).map((data, index) =>
              <div key={index} className="location">
                <div className="name">
                  JOHN SMITH, MD
                  &nbsp;|&nbsp;
                  <a href="#" target="_blank" rel="noreferrer">Physician profile</a>
                </div>
                <div className="address">
                  Pediatrics, Acupuncture
                </div>
                <div className="phone">
                  614-555-5555
                </div>
              </div>
            )}
          </div>
        }
      </Dialog>
    </>
  );
}

function OtherLocations(props) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getResults = () => {
    setLoading(true);
    getJSON('/physician-locations', {
      network: props.networkId,
      provider: props.providerId,
    }).then((response) => {
      setResults(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    getResults();
  }, []);

  if (loading) {
    return (
      <div className={"comp-modal-loading"}>
        <Loading />
      </div>
    )
  } else {
    return (
      <div className="comp-modal-grid">
        {results.map((data, index) =>
          <div key={index} className="location">
            <div className="name">
              {data.providerLocationName}
            </div>
            <div className="address">
              {data.address1 + (data.address2 ? ', ' + data.address2 : '') + ', ' + data.city + ', ' + data.state + ' ' + data.zip}
              &nbsp;|&nbsp;
              <a href={"https://maps.google.com/?q=" + data.address1 + (data.address2 ? ', ' + data.address2 : '') + ', ' + data.city + ', ' + data.state + ' ' + data.zip} target="_blank" rel="noreferrer">Directions</a>
            </div>
            <div className="phone">
              {data.phonenumber}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProviderCard;
