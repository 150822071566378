import React from 'react';
import { Rating } from "primereact/rating";

function StarRating(props) {
  return (
    props.rating ?
      <div className="comp-star-rating">
        {props.ratingUrl ?
          <a href="#" target="_blank" rel="noreferrer" className="stars" aria-label="See reviews">
            <Rating value={props.rating} offIcon="pi pi-star-fill" readOnly cancel={false} />
          </a>
          :
          <div className="stars">
            <Rating value={props.rating} offIcon="pi pi-star-fill" readOnly cancel={false} />
          </div>
        }
      </div>
    :
      <div className="comp-star-rating"></div>
  );
}

export default StarRating;
